import CertiticateChangeLogList from '../../views/worker/worker/certiticateChangeLogList'
import CertiticateProHireXpList from '../../views/worker/worker/certiticateProHireXpList'

import FileManage from '@/components/base/FileManage'

export default [
  {
    key: 'worker',
    name: '员工基础信息',
    columns: [
      { title: '工号', field: 'code' },
      { title: '机构名称', field: 'orgName' },
      { title: '姓名', field: 'name' },
      { title: '性别', field: 'sex' },
      { title: '民族', field: 'clan' },
      { title: '所属职务', field: 'positionId', text: (rowData) => {
          return rowData.positionData ? rowData.positionData.name : '无所属职务'
        }
      },
      { title: '兼职职务', field: 'positionIds', text: (rowData) => {
          return rowData.positionDatas ? rowData.positionDatas.map(v => v.name).join(',') : '无可兼职职务'
        }
      },
      { title: '默认展示机构', field: 'zhuanzhi', text: (rowData) => {
          return rowData.orgType ? rowData.orgType.name : '无默认展示机构'
        }
      },
      {
        title: '出生年月', field: 'birth', text: (rowData) => {
          return rowData && rowData.birth ? rowData.birth.slice(0, 10) : '-'
        }
      },
      { title: '年龄', field: 'age' },
      { title: '政治面貌', field: 'politicalStatus' },
      { title: '身份证号码', field: 'idNo' },
      { title: '是否在编', field: 'type' },
      { title: '在岗状态', field: 'jobTypeC' },
      { title: '工龄', field: 'maritalStatus' },
      { title: '是否港澳台及外籍人士', field: 'graduatedSchool' },
      {
        title: '参加工作时间', field: 'correctionDate', text: (rowData) => {
          return rowData && rowData.correctionDate ? rowData.correctionDate.slice(0, 10) : '-'
        }
      },
      {
        title: '进入本单位时间', field: 'entryDate', text: (rowData) => {
          return rowData && rowData.entryDate ? rowData.entryDate.slice(0, 10) : '-'
        }
      },
      { title: '人员类别', field: 'jobType' },
      { title: '是否特殊人才', field: 'agreementCode' },
      { title: '入职方式', field: 'employmentForm' },
      { title: '离职方式', field: 'discipline' },
      { title: '是否专业技术人员', field: 'address'},
      { title: '是否卫生专业技术人员', field: 'jobTitle' },
      { title: '专业技术人员专业名称', field: 'shuangjiantiao' },
      { title: '专业技术人员专业类别', field: 'email' },
      { title: '学历', field: 'education' },
      { title: '学位', field: 'degree' },
      { title: '籍贯', field: 'hometown' },
      { title: '联系电话', field: 'phone' },
      { title: '个人身份', field: 'sf' },
      {
        title: '证件照',
        field: 'headFileId',
        render: (h, value) => {
          return h(FileManage, {
            props: {
              fileDataIds: value ? [value] : [],
              funs: {get: true}
            }
          })
        }
      },
      {
        title: '身份证正面',
        field: 'idCardFrontFileId',
        render: (h, value) => {
          return h(FileManage, {
            props: {
              fileDataIds: value ? [value] : [],
              funs: {get: true}
            }
          })
        }
      },
      {
        title: '身份证反面',
        field: 'idCardBackFileId',
        render: (h, value) => {
          return h(FileManage, {
            props: {
              fileDataIds: value ? [value] : [],
              funs: {get: true}
            }
          })
        }
      }
    ]
  },
  {
    key: 'train',
    name: '培训管理',
    columns: [
      { title: '培训主办单位名称', field: 'zbdw' },
      { title: '培训类型', field: 'type' },
      { title: '出国（出境）培训标识', field: 'cg' },
      { title: '教育培训性质', field: 'jypx' },
      { title: '培训离岗状态', field: 'lg' },
      {
        title: '培训项目名称',
        field: 'pxxm',
      },
      { title: '从学单位类别', field: 'cxdw' },
      {
          title: '培训起始日期',
          field: 'startDate',
          text: (rowData) => {
              return rowData && rowData.startDate ? rowData.startDate.slice(0, 10) : '-'
          }
      },
      {
          title: '培训结束日期',
          field: 'endDate',
          text: (rowData) => {
              return rowData && rowData.endDate ? rowData.endDate.slice(0, 10) : '-'
          }
      },
      { title: '培训天数', field: 'dayNum' },
      {
        title: '扫描件',
        field: 'fileIds',
        render: (h, value) => {
          return h(FileManage, {
            props: {
              fileDataIds: value ? value.split(',') : [],
              funs: {get: true}
            }
          })
        }
      }
    ]
  },
  {
    key: 'rew',
    name: '奖励信息',
    columns: [
      // { title: '员工姓名', field: 'workerName' },
      // { title: '员工工号', field: 'workerCode' },
      // { title: '身份证号', field: 'idNo' },
      { title: '奖励名称', field: 'title' },
      {
        title: '奖励批准日期', field: 'pzsj', text: (rowData) => {
          return rowData && rowData.pzsj ? rowData.pzsj.slice(0, 10) : '-'
        }
      },
      { title: '奖励批准机关名称', field: 'pzdw' },
      { title: '授予荣誉称号的级别', field: 'level' },
      { title: '奖励原因', field: 'reson' },
      { title: '批准奖励文件名称', field: 'pzwj' },
      { title: '奖励文件文号', field: 'pzwjh' },
      {
        title: '奖励撤销日期', field: 'jcsj', text: (rowData) => {
          return rowData && rowData.jcsj ? rowData.jcsj.slice(0, 10) : '-'
        }
      },
      { title: '奖励撤销原因', field: 'jcyy' },
      {
        title: '扫描件',
        field: 'fileIds',
        render: (h, value) => {
          return h(FileManage, {
            props: {
              fileDataIds: value ? value.split(',') : [],
              funs: {get: true}
            }
          })
        }
      }
    ]
  },
  {
    key: 'worker_edu',
    name: '教育经历',
    columns: [{
    //   title: '员工姓名',
    //   field: 'workerName'
    // },
    // {
    //   title: '员工工号',
    //   field: 'workerCode'
    // },
    // {
      title: '学历',
      field: 'edu'
    },
    { title: '学位', field: 'degree' },
    {
      title: '毕业院校',
      field: 'school'
    },
    {
      title: '学校编码',
      field: 'schoolCode',
    },
    {
      title: '专业',
      field: 'pro'
    },
    {
      title: '入学时间',
      field: 'startDate',
      text: (rowData) => {
        return rowData && rowData.startDate ? rowData.startDate.slice(0, 10) : '-'
      }
    },
    {
      title: '毕业时间',
      field: 'endDate',
      text: (rowData) => {
        return rowData && rowData.endDate ? rowData.endDate.slice(0, 10) : '-'
      }
    },
    {
      title: '学制',
      field: 'xz'
    },
    {
      title: '证书编号',
      field: 'zsCode'
    },
    {
      title: '教育形式',
      field: 'jyxs'
    },
    {
      title: '学历类型',
      field: 'type'
    },
    {
      title: '扫描件',
      field: 'fileIds',
      render: (h, value) => {
        return h(FileManage, {
          props: {
            fileDataIds: value ? value.split(',') : [],
            funs: {get: true}
          }
        })
      }
    }]
  },
  {
    key: 'his_work',
    name: '工作记录',
    columns: [
      // {
      //   title: '员工姓名',
      //   field: 'workerName',
      // },
      // {
      //   title: '员工工号',
      //   field: 'workerCode',
      // },
      {
        title: '工作单位',
        field: 'orgName',
      },
      {
        title: '担任职务',
        field: 'jobTitle',
      },
      {
        title: '开始时间',
        field: 'startTime',
        text: (rowData) => {
          return rowData && rowData.startTime ? rowData.startTime.slice(0, 10) : '-'
        }
      },
      {
        title: '结束时间',
        field: 'endTime',
        text: (rowData) => {
          return rowData && rowData.endTime ? rowData.endTime.slice(0, 10) : '至今'
        }
      },
      {
        title: '扫描件',
        field: 'fileIds',
        render: (h, value) => {
          return h(FileManage, {
            props: {
              fileDataIds: value ? value.split(',') : [],
              funs: {get: true}
            }
          })
        }
      }
    ]
  },
  {
    key: 'worker_certificate_doctor_pra',
    name: '执业证书',
    columns: [
      // {
      //   title: '编号',
      //   field: 'id'
      // },
      {
        title: '证书编码',
        field: 'code'
      },
      // {
      //   title: '类型', field: 'type', text: function (row) {
      //     return (this.$store.getters.certificateTypeList.find(i => i.key === row.type) || {label: row.type}).label
      //   }
      // },
      // {
      //   title: '员工姓名',
      //   field: 'workerName'
      // },
      // {
      //   title: '员工工号',
      //   field: 'workerCode'
      // },
      {
        title: '发证机关',
        field: 'issueOrg'
      },
      {
        title: '签发人',
        field: 'issueUser'
      },
      {
        title: '发证日期',
        field: 'getDate',
        dataType: Date,
        text: (rowData) => {
          return rowData && rowData.getDate ? rowData.getDate.slice(0, 10) : '-'
        }
      },
      {
        title: '有效期开始时间',
        field: 'hireStartDate',
        dataType: Date,
        text: (rowData) => {
          return rowData && rowData.hireStartDate ? rowData.hireStartDate.slice(0, 10) : '-'
        }
      },
      {
        title: '有效期结束时间',
        field: 'hireEndDate',
        dataType: Date,
        text: (rowData) => {
          return rowData && rowData.hireEndDate ? rowData.hireEndDate.slice(0, 10) : '-'
        }
      },
      // {
      //   title: '性别',
      //   field: 'sex'
      // },
      // {
      //   title: '出生日期',
      //   field: 'birth',
      //   text: (rowData) => {
      //     return rowData && rowData.birth ? rowData.birth.slice(0, 10) : '-'
      //   }
      // },
      // {
      //   title: '身份证号',
      //   field: 'idNo'
      // },
      {
        title: '执业地点',
        field: 'practiceAddress'
      },
      {
        title: '执业类别',
        field: 'practiceType'
      },
      {
        title: '执业范围',
        field: 'practiceRange'
      },
      {
        title: '变更记录',
        field: 'updateLog',
        render: (h, value) => {
          return h(CertiticateChangeLogList, {
            props: {
              value: value
            }
          })
        }
      },
      {
        title: '证件状态',
        field: 'status'
      // },
      // {
      //   title: '扫描件状态', field: 'imgStatus', text: (row) => {
      //     return row.imgUploadTime ? '已上传' : '未上传'
      //   }
      // },
      // {
      //   title: '最近更新扫描件时间',
      //   field: 'imgUploadTime'
      },
      {
        title: '最近更新扫描件时间',
        field: 'imgUploadTime',
        text: (rowData) => {
          return rowData && rowData.imgUploadTime ? rowData.imgUploadTime.slice(0, 10) : '-'
        }
      },
      {
        title: '扫描件',
        field: 'fileIds',
        render: (h, value) => {
          return h(FileManage, {
            props: {
              fileDataIds: value ? value.split(',') : [],
              funs: {get: true}
            }
          })
        }
      }
    ]
  },
  {
    key: 'worker_certificate_pro_hire',
    name: '专业技术职务聘任证书',
    columns: [
      // {
      //   title: '编号',
      //   field: 'id',
      // },
      {
        title: '证书编码',
        field: 'code',
      },
      // {
      //   title: '类型', field: 'type', text: function (row) {
      //     return (this.$store.getters.certificateTypeList.find(i => i.key === row.type) || {label: row.type}).label
      //   }
      // },
      // {
      //   title: '员工姓名',
      //   field: 'workerName',
      // },
      // {
      //   title: '员工工号',
      //   field: 'workerCode',
      // },
      // {
      //   title: '性别',
      //   field: 'sex',
      // },
      {
        title: '专业',
        field: 'hirePro',
      },
      {
        title: '聘任单位',
        field: 'hireOrg',
      },
      {
        title: '聘任职务',
        field: 'hireWorker',
      },
      {
        title: '岗位类型',
        field: 'xlmc'
      },
      {
        title: '岗位等级',
        field: 'mType'
      },
      {
        title: '聘期起始日期',
        field: 'hireStartDate',
        text: (rowData) => {
          return rowData && rowData.hireStartDate ? rowData.hireStartDate.slice(0, 10) : '-'
        }
      },
      {
        title: '聘期终止日期',
        field: 'hireEndDate',
        text: (rowData) => {
          return rowData && rowData.hireEndDate ? rowData.hireEndDate.slice(0, 10) : '-'
        }
      },
      {
        title: '证件状态',
        field: 'status',
      // },
      // {
      //   title: '扫描件状态', field: 'imgStatus', text: (row) => {
      //     return row.imgUploadTime ? '已上传' : '未上传'
      //   }
      // },
      // {
      //   title: '最近更新扫描件时间',
      //   field: 'imgUploadTime'
      },
      {
        title: '续聘记录',
        field: 'updateLog',
        render: (h, value) => {
          return h(CertiticateProHireXpList, {
            props: {
              value: value
            }
          })
        }
      },
      {
        title: '最近更新扫描件时间',
        field: 'imgUploadTime',
        text: (rowData) => {
          return rowData && rowData.imgUploadTime ? rowData.imgUploadTime.slice(0, 10) : '-'
        }
      },
      {
        title: '扫描件',
        field: 'fileIds',
        render: (h, value) => {
          return h(FileManage, {
            props: {
              fileDataIds: value ? value.split(',') : [],
              funs: {get: true}
            }
          })
        }
      }
    ]
  },
  {
    key: 'worker_certificate_pro_qua',
    name: '专业技术资格证书',
    columns: [
      {
        title: '证书编码',
        field: 'code'
      },
      // {
      //   title: '管理号',
      //   field: 'gradSchool'
      // },
      // {
      //   title: '工作单位',
      //   field: 'workerOrg'
      // },
      {
        title: '系列名称/类别',
        field: 'xlmc'
      },
      {
        title: '专业名称',
        field: 'pro'
      },
      {
        title: '资格名称',
        field: 'certificateId',
        text: (rowData) => {
          return rowData && rowData.certificateTitle ? rowData.certificateTitle : '-'
        }
      },
      {
        title: '授予(批准)时间',
        field: 'getDate',
        text: (rowData) => {
          return rowData && rowData.getDate ? rowData.getDate.slice(0, 10) : '-'
        }
      },
      {
        title: '批准文号',
        field: 'practiceType'
      },
      {
        title: '发证时间',
        field: 'hireStartDate',
        text: (rowData) => {
          return rowData && rowData.hireStartDate ? rowData.hireStartDate.slice(0, 10) : '-'
        }
      },
      {
        title: '最近更新扫描件时间',
        field: 'imgUploadTime',
        text: (rowData) => {
          return rowData && rowData.imgUploadTime ? rowData.imgUploadTime.slice(0, 10) : '-'
        }
      },
      {
        title: '扫描件',
        field: 'fileIds',
        render: (h, value) => {
          return h(FileManage, {
            props: {
              fileDataIds: value ? value.split(',') : [],
              funs: {get: true}
            }
          })
        }
      }
    ]
  },
  {
    key: 'worker_certificate_doctor_qua',
    name: '职业资格证书',
    columns: [
      // {
      //   title: '编号',
      //   field: 'id',
      // },
      {
        title: '证书编码',
        field: 'code',
      },
      // {
      //   title: '类型',
      //   field: 'type',
      //   text: function (row) {
      //     return (this.$store.getters.certificateTypeList.find(i => i.key === row.type) || {label: row.type}).label
      //   }
      // },
      // {
      //   title: '员工姓名',
      //   field: 'workerName',
      // },
      // {
      //   title: '员工工号',
      //   field: 'workerCode',
      // },
      {
        title: '发证机关',
        field: 'issueOrg',
      },
      {
        title: '签发人',
        field: 'issueUser',
      },
      {
        title: '发证日期',
        field: 'getDate',
        text: (rowData) => {
          return rowData && rowData.getDate ? rowData.getDate.slice(0, 10) : '-'
        }
      },
      // {
      //   title: '性别',
      //   field: 'sex',
      // },
      // {
      //   title: '出生日期',
      //   field: 'birth',
      //   text: (rowData) => {
      //     return rowData && rowData.birth ? rowData.birth.slice(0, 10) : '-'
      //   }
      // },
      // {
      //   title: '身份证号',
      //   field: 'idNo',
      // },
      {
        title: '学历',
        field: 'workerEducation',
      },
      {
        title: '毕业学校',
        field: 'gradSchool',
      },
      {
        title: '专业',
        field: 'pro',
      },
      {
        title: '类别',
        field: 'mType',
      },
      {
        title: '证件状态',
        field: 'status'
      },
      {
        title: '最近更新扫描件时间',
        field: 'imgUploadTime',
        text: (rowData) => {
          return rowData && rowData.imgUploadTime ? rowData.imgUploadTime.slice(0, 10) : '-'
        }
      },
      {
        title: '扫描件',
        field: 'fileIds',
        render: (h, value) => {
          return h(FileManage, {
            props: {
              fileDataIds: value ? value.split(',') : [],
              funs: {get: true}
            }
          })
        }
      }
    ]
  },
  {
    key: 'worker_certificate_other',
    name: '其他证书',
    columns: [
      {
        title: '证书名称',
        field: 'mType',
      },
      {
        title: '证书编码',
        field: 'code',
      },
      {
        title: '证件状态',
        field: 'status',
      },
      {
        title: '最近更新扫描件时间',
        field: 'imgUploadTime',
        text: (rowData) => {
          return rowData && rowData.imgUploadTime ? rowData.imgUploadTime.slice(0, 10) : '-'
        }
      },
      {
        title: '扫描件',
        field: 'fileIds',
        render: (h, value) => {
          return h(FileManage, {
            props: {
              fileDataIds: value ? value.split(',') : [],
              funs: {get: true}
            }
          })
        }
      }
    ]
  },
  {
    key: 'worker_job_title',
    name: '职称聘任记录',
    columns: [
      // {
      //   title: '员工编号',
      //   field: 'workerCode'
      // },
      // {
      //   title: '职工',
      //   field: 'workerName'
      // },
      // {
      //   title: '岗位专业',
      //   field: 'type'
      // },
      // {
      //   title: '岗位类型',
      //   field: 'jobTitleType'
      // },
      // {
      //   title: '岗位层级',
      //   field: 'topLevel'
      // },
      // {
      //   title: '职称级别',
      //   field: 'mLevel'
      // },
      // {
      //   title: '岗位等级',
      //   field: 'jobTitleTitle'
      // },
      { title: '岗位', field: 'jobTitleId', text: (rowData) => {
          return rowData.jobTitleType + ' ' + rowData.jobTitleTitle
        }
      },
      {
        title: '上岗时间',
        field: 'startDate',
        text: (rowData) => {
          return rowData && rowData.startDate ? rowData.startDate.slice(0, 10) : '-'
        }
      },
      {
        title: '离任时间',
        field: 'endDate',
        text: (rowData) => {
          return rowData && rowData.endDate ? rowData.endDate.slice(0, 10) : '-'
        }
      },
      {
        title: '聘任起始时间',
        field: 'hireStartDate',
        text: (rowData) => {
          return rowData && rowData.hireStartDate ? rowData.hireStartDate.slice(0, 10) : '-'
        }
      },
      {
        title: '聘任终止时间',
        field: 'hireEndDate',
        text: (rowData) => {
          return rowData && rowData.hireEndDate ? rowData.hireEndDate.slice(0, 10) : '至今'
        }
      },
      {
        title: '在岗单位',
        field: 'hisName'
      },
      {
        title: '累计聘任上岗年限',
        field: 'years',
      }
    ]
  }
]